<script>
export default {
  name: "ProjectComments",

  props: { 
    projectId: [Number, String]
  },

  data() { return {
    comments: [],
    authors: {},

    inputValue: "",

    showLoadMoreBtn: false,
    requestWait: false,
    postCommentWait: false,
  }},

  computed: {
    commentsLength() {
      return this.comments.length;
    },

    isInputValid() {
      return this.inputValue && this.inputValue.replace(/\s/g, '').length;
    },
  },

  created() {
    this.RequestComments();
  },

  methods: {
    RequestComments()
    {
      const params = {
        start: this.commentsLength
      };

      this.requestWait = true;
      this.$api.get({ path: `projects/${this.projectId}/comments`, params }, this.OnRequestResponse)
    },

    OnRequestResponse({response})
    {
      const { comments, authors } = response.data;

      this.comments = this.comments.concat(comments);
      Object.assign(this.authors, authors);

      this.requestWait = false;
      this.showLoadMoreBtn = response.metadata.comments_length >= 5;
    },

    PostComment()
    {
      if(!this.isInputValid) {
        this.$refs.inputComment.focus()
        return;
      }

      const data = {
        text: this.inputValue
      };

      this.postCommentWait = true;
      this.$api.post({ path: `projects/${this.projectId}/comments`, data }, this.OnPostResponse);
    },

    OnPostResponse({response})
    {
      const { comment, author } = response.data;
      this.comments.unshift(comment);

      this.authors[author.id] = author;

      this.$refs.inputComment.clear();
      this.postCommentWait = false;
    },

    ToggleView()
    {
      this.expanded = !this.expanded
    }
  }
}

</script>

<template>


  <div class="project-comments" ref="commentsBody">

    <div class="comments-list">
      <message-box class="no-comments" v-if="!commentsLength">
        <icon>forum</icon>
        {{$lan("no_comments")}}
      </message-box>


      <comment v-for="(comment, index) in comments" :key="index" :id="index" :data="comment" :author="authors[comment.u_id]">
      </comment>
    
      <l-button class="load-more" :active="requestWait" v-if="showLoadMoreBtn" @click="RequestComments">
        {{$lan("load_more_btn")}}
      </l-button>
    </div>

    <div class="footer">
      <!-- <avatar :url="$session.user.picture" :f-name="$session.user.name" :s-name="$session.user.sname"></avatar> -->

      <rich-text-area maxlength="5000" class="comment-input shadow-focus" ref="inputComment" v-model:value="inputValue" placeholder="Comment">
      </rich-text-area>

      <icon-button :disabled="postCommentWait" @click="PostComment" class="blue send-btn">
        send
      </icon-button>
    </div>
  </div>

</template>

<style scoped>
.project-comments {
  /* padding: 10px; */
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.comments-box {
  display: flex;
  flex-direction: column;
  padding: 15px;
  /* max-height: 500px; */
}


.no-comments {
  /* background: var(--dark-box-color); */
  padding: 20px;
  margin-top: 25px;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  margin: 5px;
  width: 95%;
}

.comments-list {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 10px;
  height: 500px;
  overflow: auto;
  gap: 10px;
  padding: 5px;
}

.footer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 5px;
  padding-right: 20px;
  margin: 10px;
  gap: 10px;
}

.comment-input {
  position: relative;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  height: 80px;
  width: 100%;
}

.rich-text-editor {
  position: relative;
  flex-direction: row;
  width: 100%;
  color: var(--text-color-strong);
}

.comment {
  animation: slide-down 0.2s;
}

.load-more {
  margin: auto;
}

.send-btn {
  right: 35px;
}
</style>