<script>
import ProjectComments from './ProjectComments.vue';
import ProjectMembers from './ProjectMembers/ProjectMembers.vue';

export default {
  name: "ProjectTabs",

  components: { ProjectComments, ProjectMembers },

  props: {
    projectId: [Number, String]
  },
  
  computed: {
    tab() {
      return this.$route.query.tab;
    }
  },

  mounted()
  {
    this.SelectTab("members")
  },

  methods: {
    SelectTab(tabName) {
      this.$router.replace({ query: { tab: tabName } });
    }
  },

}
</script>

<template>
  <div class="box project-tabs">

    <div class="header title-bar buttons-group">
      <button @click="SelectTab('members')" class="no-bg tab-btn" :active="tab == 'members'">
        Membrii
      </button>
      <button @click="SelectTab('comments')" class="no-bg tab-btn" :active="tab == 'comments'">
        Comentarii
      </button>
    </div>
  
    <project-comments v-if="tab == 'comments'" :project-id="projectId"></project-comments>
    <project-members v-if="tab == 'members'" :project-id="projectId"></project-members>

  </div>
</template>



<style scoped>
.project-tabs {
  display: flex;
  flex-direction: column;
  background-color: var(--box-color2);
  min-width: 350px;
  max-width: 550px;
  min-height: 400px;
  overflow: hidden;
}


.header {
  display: flex;
  padding: 15px;
  margin-bottom: 5px;
}

.header button {
  padding: 10px;
  border-radius: 5px;
}

.tab-btn[active = 'true'] {
  background-color: var(--box-color-blue);
  border: var(--solid-border);
}

.buttons-group {
  justify-content: left;
}

@media only screen and (max-width: 1100px) 
{
  .project-tabs {
    max-width: 100%;
  }
}
</style>