<script>
import ProjectTabs from './ProjectTabs.vue';
// import ProjectTasks from './ProjectTasks.vue'
// import ProjectComments from './ProjectComments.vue'

export default {
  name: "ProjectComponent",
  components: { ProjectTabs },

  data() { return {
    projectData: {
      u_id: 0,
    },

    authorData: {
      f_name: "A",
      s_name: "U",
      is_active: false
    },

    edit: false
  }},

  computed: {
    projectId()
    {
      return this.$route.params.id;
    },

    authorId()
    {
      return this.projectData?.u_id;
    }
  },

  created()
  {
    this.RequestProjectData();
  },

  methods: {
    RequestProjectData()
    {
      this.$api.get({ path: `projects/${this.projectId}` }, this.OnProjectDataResponse);
    },

    OnProjectDataResponse({response})
    {
      const { project } = response.data;
      this.projectData = project;

      this.RequestAuthorData();
    },

    RequestAuthorData()
    {
      this.$api.get({ path: `users/${this.authorId}/profile` }, this.OnAuthorDataResponse);
    },

    OnAuthorDataResponse({response})
    {
      const { user } = response.data;
      this.authorData = user;
    },

    DeleteProject()
    {
      this.$pop.question("Șterge proiect", "Ești sigur că vrei să stergi acest proiect?", () => {
        this.OnConfirmDeleteProject();
      });
    },

    OnConfirmDeleteProject()
    {
      this.$api.delete({ path: `projects/${this.projectId}` }, () => { 
        this.$router.push("/projects");
      });
    },

    SaveChanges()
    {
      const data = {
        title: this.projectData.title,
        info: this.projectData.info
      };

      this.$api.patch({ path: `projects/${this.projectId}`, data }, () => {
        this.edit = false;
      });
    },

    ShareProject()
    {
      navigator.clipboard.writeText(window.location.href);
      this.$msg.push(this.$lan("project_share_success"));
    }
  },


};
</script>

<template>
  <div class="project-body">
    <div class="box project-content">

      <div class="box simple-box project-box project-banner">
        <img class="project-banner-img" src="/img/blue_project_banner.svg" />
        
        <input maxlength="70" v-if="edit" v-model="projectData.title" class="project-title" style="margin-right: 150px;"/>

        <h2 v-else class="project-title" style="margin-right: 150px;">
          {{projectData.title}}
        </h2>
        <p class="project-title" style="opacity: 0.9;">
          Postat: {{$utils.TimeStampToString(projectData.created)}}
        </p>

        <div class="author-container">
          <profile-preview :user-target="projectData.u_id">
            <avatar :url="authorData.picture" :f-name="authorData.f_name" :s-name="authorData.s_name" :active="authorData.is_active"></avatar>
          </profile-preview>
        </div>

        <div v-if="$session.user.can('manage_projects')" class="project-buttons">
          <icon-button @click="DeleteProject" v-tooltip="'Șterge proiect'">
            delete
          </icon-button>
          <icon-button v-if="edit" @click="SaveChanges" v-tooltip="'Salvează'">
            save
          </icon-button>
          <icon-button v-else @click="edit = true" v-tooltip="'Modifică'">
            edit
          </icon-button>
          <icon-button @click="ShareProject" v-tooltip="'Trimite'">
            link
          </icon-button>
        </div>
      </div>

      <div class="simple-box project-box">
        <rich-textarea 
          placeholder="Project info..." 
          maxlength="10000" 
          class="project-text" 
          v-model:value="projectData.info"
          v-if="edit"
        />

        <div v-else class="input project-text info-box" v-html="projectData.info" />
      </div>

    </div>

    <project-tabs :project-id="projectId"></project-tabs>
  </div>
 
 
</template>


<style scoped>

.project-body {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 50px;
}

.simple-box {
  position: relative;
  border-radius: 20px;
}

.project-content {
  width: 70%;
  min-width: 350px;
  height: fit-content;
}

.project-box {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  margin-bottom: 10px;
  animation: zoom-in 0.1s;
}


.project-banner {
  position: relative;
  overflow: hidden;
  height: 150px;
  padding: 10px;
}

.project-banner:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.project-banner-img {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-duration: 0.3s;
}

.project-banner:hover .project-banner-img {
  filter: brightness(50%);
}

.author-container {
  margin-right: 15px;
  margin-bottom: 10px;
  bottom: 0px;
  right: 0px;
  position: absolute;
}

.user-pic {
  outline: solid 1px white !important;
}

.user-pic:hover {
  outline: solid 1px rgba(224, 224, 224, 0.788) !important;
}

.project-title {
  position: relative;
  top: 15px;
  color: rgb(252, 252, 252);
  margin: 10px;
  margin-left: 20px;
}

.project-text {
  position: relative;
  flex-grow: 1;
  margin: 15px;
  background: var(--box-color2);;
  height: 500px;
  font-size: 18px;
  color: var(--text-color-strong);
}

.project-buttons {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  flex-direction: row;
  margin: 10px;
}

.project-buttons button {
  color: white;
  background: none;
}

.project-banner:hover .project-buttons {
  display: flex;
}

.user-row {
  position: relative;
  height: fit-content;
  width: 100%;
  border-radius: 15px;
  margin-top: 5px;
  vertical-align: center;
  padding-block: 5px;
}

.user-row:hover {
  background-color: var(--dark-box-color);;
}

.picture {
  position: relative;
  float: left;
  width: 60px;
  height: 60px;
  margin-left: 15px;
}

.name {
  font-weight: bold;
  position: relative;
  color: var(--text-color);
  font-size: 20px;
}

.text-box {
  padding-top: 10px;
  float: left;
  align-items: center;
  margin-left: 10px;
}

.email {
  display: block;
  font-weight: bold;
  font-size: 13px;
}

.user-buttons {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  height: 100%;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}


.user-row:hover .user-buttons {
  display: flex;
}

.info-box {
  overflow-y: auto;
  border: none;
  margin: 10px;
  line-height: 22px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.project-footer {
  width: 100%;
  display: flex;
  gap: 10px;
}


@media only screen and (max-width: 1100px) 
{
  .project-body {
    padding-bottom: 50px;
  }

  .simple-box, .project-content {
    width: 100% !important;
    margin-inline: 0;
    min-width: auto;
  }

  .project-footer {
    flex-wrap: wrap;
  }
}
</style>

