<script>
import LoadingBox from '../../../Common/LoadingBox.vue';
export default {
  components: { LoadingBox },
  name: "MemberRow",

  props: {
    userData: Object,
    projectId: [Number,String]
  },
  

  data() {return {
    expanded: false,
    tasks: [],
    waitResponse: true,
  }},

  computed: {
    userId() {
      return this.userData?.id;
    },

    tasksLength() {
      return this.tasks.length;
    }
  },

  methods: {
    Expand()
    {
      this.expanded = !this.expanded;

      this.RequestTasks();
    },

    RequestTasks()
    {
      if(!this.expanded) {
        return;
      }

      const params = {
        "user-id": this.userId
      };

      this.waitResponse = true;
      this.$api.get({ path: `projects/${this.projectId}/tasks`, params }, this.OnRequestResponse);
    },

    OnRequestResponse({response}) 
    {
      const { project_tasks } = response.data;
      this.tasks = project_tasks;
      this.waitResponse = false;
    },

    OpenTask(taskId)
    {
      this.$router.push({ path: `/`, query: { task_id: taskId } })
    }
  }
}
</script>

<template>
  <div class="member-row click-area" :expanded="expanded">
    <user-row :user-data="userData" @click.self="Expand"></user-row>

    <div v-if="expanded" class="project-tasks">
      <div class="project-task" v-if="!tasksLength && !waitResponse">
        {{$lan("no_tasks")}}
      </div>

      <loading-box v-if="waitResponse"></loading-box>

      <div class="project-task" v-for="(task, index) in tasks" :key="index" @click="OpenTask(task.id)">
        <div class="title">
          {{task.info}}
        </div>
        <div class="content">
          Termen Limită: {{ $utils.TimeStampToInputVal(task.deadline) }}
        </div>
        <div class="content">
          Status: {{ $lan(`task_status_${task.status}`)  }}
        </div>

      </div>
    </div>

  </div>
</template>


<style scoped>
.member-row {
  border-radius: 30px;
  /* overflow: hidden;
  flex-grow: 1; */
}

.project-tasks {
  max-height: 300px;
  overflow: auto;
}

.user-row:after {
  content: "expand_more";
  position: absolute;
  right: 10px;
  font-family: "Material Icons";
  font-size: x-large;
  transition: 0.3s;
}

.user-row {
  border-radius: inherit;
}

.member-row[expanded=true] {
  height: fit-content;
}

.member-row[expanded=true] .user-row {
  background-color: var(--box-color-blue);
}

.member-row[expanded=true] .user-row:after {
  transform: rotate(180deg);
}

.label-title {
  margin: 5px;
}

.project-task {
  position: relative;
  padding: 10px;
  overflow: hidden;
  margin: 5px;
  border: solid 1px var(--border-color);
  border-radius: 10px;
}

.project-task .content {
  font-size: small;
}

.project-task .title {
  padding-block: 5px;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: medium;
  font-weight: bold;
  white-space: nowrap;
}

.project-task:hover {
  border-color: var(--theme-color);
}

.message-box {
  background-color: var(--box-color);
}
</style>