<script>
import MemberRow from './MemberRow.vue';


export default {
  name: "ProjectMembers",
  components: { MemberRow },

  props: {
    projectId: [Number, String]
  },

  data() { return {
    members: [],
  }},

  computed: {
    membersCount() {
      return this.members.length;
    }
  },

  created()
  {
    this.RequestMembers();
  },

  methods: {
    RequestMembers()
    {
      this.$api.get({ path: `projects/${this.projectId}/members` }, this.OnMembersResponse);
    },

    OnMembersResponse({response})
    {
      const { members } = response.data;
      this.members = members;
    },

    OpenMembersEditor()
    {
      const params = {
        members: this.members,
        projectId: this.projectId
      };

      this.$modal.Open('ProjectMembersModal', { context: this, params });
    }
  }
}
</script>

<template>
  <div class="project-members">
    <header>
      <button class="no-bg border-btn" v-if="$session.HasAccess(['manage_projects'])" @click="OpenMembersEditor">
        <icon>groups</icon>Editeaza membrii
      </button>
    </header>

    <member-row v-for="(member, index) in members" :key="index" :project-id="projectId" :user-data="member">
    </member-row>
  </div>
</template>



<style scoped>
.project-members {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 600px;
  gap: 5px;
  padding: 5px;
}

.project-members header {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.project-members header button {
  flex: 1;
}
</style>