<script>
import Project from '../../components/ProjectView/ProjectComponent/Project.vue'

export default {
  name: "ProjectPage",
  components: { 
    'project-view': Project
   }

};
</script>

<template>
  <project-view />
</template>

